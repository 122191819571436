<template>
  <span class="grid aspect-square grid-cols-3 gap-[7.5%]">
    <span
      v-for="dot in state.dots"
      :style="{ scale: dot }"
      class="dot size-full rounded-full bg-current"
    ></span>
  </span>
</template>

<script lang="ts" setup>
import { useIntervalFn } from '@vueuse/core'

const state = reactive({
  dots: ['60%', '89%', '61%', '87%', '94%', '32%', '55%', '44%', '98%'],
})

useIntervalFn(() => {
  setRandomAmount()
}, 2500)

const minScale = 32
const maxScale = 100
const deviation = 15

function setRandomAmount() {
  state.dots.forEach((el, i) => {
    let randomNumber = Math.floor(
      Math.random() * (maxScale - minScale + 1) + minScale
    )
    let min = parseInt(state.dots[i]) + deviation
    let max = parseInt(state.dots[i]) - deviation

    randomNumber =
      randomNumber <= min || randomNumber >= max
        ? randomNumber
        : [minScale, maxScale][Math.floor(Math.random())]
    state.dots[i] = randomNumber + '%'
  })
}
</script>

<style lang="scss" scoped>
.dot {
  transition: scale 2.5s cubic-bezier(0.15, 0.64, 0.27, 1.65);
  $delay-increment: 0.1s;
  @for $i from 1 through 9 {
    &:nth-child(#{$i}) {
      transition-delay: $i * $delay-increment;
    }
  }
}
</style>
